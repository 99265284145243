<template>
  <div class="container">
    <div class="row marginTop">
      <div class="col s12 center-align red marginTop">
        <h1 class = 'white-text marginBottom'><i class='fas fa-ban'></i></h1>
        <h5 class = 'white-text marginBottom'>Usuario Bloqueado!</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .red{
    height: 500px;
  }

  h1{
    margin-top: 150px;
  }

  .fa-ban{
    font-size: 120px;
  }
</style>